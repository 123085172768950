import React, {Component, Fragment} from 'react';
import cv from "../../assets/img/Cv_Botiveau_Jerome_2022.png";
import cvPdf from "../../assets/img/Cv_Botiveau_Jerome_2022.pdf";
import AOS from 'aos';
import 'aos/dist/aos.css';

export default class CV extends Component {

    constructor(props) {
        super(props);
        AOS.init({ duration : 2000});
    }

    render(){
        return(
            <Fragment>
                <div className={'container-fluid-md container-lg bef-cv'}>
                    <div className="cv">
                        <div className={"row"}>
                            <div className={"col-lg-6 col-md-5"}>
                                <h2 className={"cv__title cv__left"}>Mon CV</h2>
                                <div className={"cv__image"}>
                                    <div className={"mr-5"}>
                                        <img className={'image--view'} src={cv} alt={"CV"}/>
                                    </div>
                                    <div className={"mt-3 button__download d-flex justify-content-center"}>
                                        <a className={"image--download"} href={cvPdf} download>Télécharger le pdf</a>
                                    </div>
                                </div>
                            </div>
                            <div className={'col-lg-6 col-md-7'}>
                                <h2 className={"cv__title text-center mb-4 mr--title"}>Mon parcours</h2>
                                <div className={"row"}>
                                    <div className={'col-md-5 col-lg-5'}>
                                        <div className={"cv__info"}>
                                            <h3 className={"info__title"}>Web@cademie</h3>
                                            <h4 className={"info__date"}>novembre 2020-2022</h4>
                                        </div>
                                        <div className={"info__decoration"}></div>
                                        <i data-aos={"zoom-in"} className="cv__icon cv__icon--first fas fa-school"></i>
                                    </div>

                                    <div className={"col-md-6 cv__description"}>
                                        <h5>Formation développeur web</h5>
                                        <ul>
                                            <li>
                                                <div className={"description__list"}>
                                                    <p className={"description__paragraph"}>Épitech Rennes</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className={"row"}>
                                    <div className={'col-md-5 cv__info--container'}>
                                        <div className={"cv__info"}>
                                            <h3 className={"info__title"}>Botte fondation</h3>
                                            <h4 className={"info__date"}>2014-2020</h4>
                                        </div>
                                        <i data-aos={"zoom-in"} className="cv__icon fas fa-hard-hat"></i>
                                    </div>

                                    <div className={"col-md-6 cv__description"}>
                                        <h5>Travaux spéciaux</h5>
                                        <ul>
                                            <li>
                                                <div className={"description__list"}>
                                                    <p className={"description__paragraph"}>Ouvrier des fondations</p>
                                                    <p className={"description__paragraph"}>puis Chef d'équipe</p>
                                                </div>
                                            </li>
                                            <li>
                                                <p className={"description__paragraph"}>lecture de plan</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className={"row"}>

                                    <div className={'col-md-5 cv__info--container'}>
                                        <div className={"cv__info"}>
                                            <h3 className={"info__title"}>Création-bati-jardin</h3>
                                            <h4 className={"info__date"}>2010-2014</h4>
                                        </div>
                                        <i data-aos={"zoom-in"} className="cv__icon fas fa-hard-hat"></i>
                                    </div>

                                    <div className={"col-md-6 cv__description"}>
                                        <h5>Réalisation de jardin</h5>
                                        <ul>
                                            <li>
                                                <div className={"description__list"}>
                                                    <p className={"description__paragraph"}>ouvrier qualifié</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <h2 className={"cv__title text-center mt-4 mb-3 mr--title"}>Mes diplômes</h2>
                                <div className={"row"}>

                                    <div className={'col-md-5 cv__info--container'}>
                                        <div className={"cv__info--school"}>
                                            <h3 className={"info__title"}>BP travaux paysagers</h3>
                                        </div>
                                        <i data-aos={"zoom-in"} className="fas fa-graduation-cap cv__icon"></i>
                                    </div>

                                    <div className={"col-md-6 cv__description"}>
                                        <h5>Lycée jules rieffel</h5>
                                        <p className={"info__date--school"}>2007-2009</p>
                                    </div>
                                </div>
                                <div className={"row"}>
                                    <div className={'col-md-5 cv__info--container'}>
                                        <div className={"cv__info--school"}>
                                            <h3 className={"info__title"}>BEP travaux paysagers</h3>
                                        </div>
                                        <i data-aos={"zoom-in"} className="fas fa-graduation-cap cv__icon"></i>
                                    </div>

                                    <div className={"col-md-6 cv__description"}>
                                        <p className={"info__date--school"}>2005-2007</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
