import React, {Component, Fragment} from 'react';
import { Link } from 'react-scroll';

export default class Navbar extends Component {

    render(){
        return(
            <Fragment>
                <nav className="navbar navbar-expand-md navbar-light bg-light fixed-top">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="navbar collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav mr-auto ml-auto">
                            <Fragment>
                                <li className="nav-item active navbar__link--decal">
                                    <Link to={"bef-presentation"} className="nav-link" offset={-70} smooth={true} duration={0}>Accueil</Link>
                                </li>
                                <li className="nav-item active">
                                    <Link to={"bef-cv"} className="nav-link" smooth={true} offset={-70} duration={0}>Parcours</Link>
                                </li>
                                <li className="nav-item active">
                                    <Link to={"bef-skills"} className="nav-link" smooth={true} offset={-140} duration={0}>Compétences</Link>
                                </li>
                                <li className="nav-item nav-realisation active">
                                    <Link id={"projectLink"} to={"bef-project"} className="nav-link" offset={-80} smooth={true} duration={0}>Réalisation</Link>
                                </li>
                                {/*<li>
                                    <Link className="navbar__btn" to={'bef-contact'} smooth={true} offset={-70} duration={0}>Me contacter</Link>
        </li>*/}
                            </Fragment>
                        </ul>
                    </div>
                </nav>
            </Fragment>
        )
    }
}
