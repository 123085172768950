import React, {Component, Fragment} from 'react';
import SliderModule from "../slider/slider";

export default class Project extends Component {

    render(){
        return(
            <Fragment>
                <div className={'container bef-project'}>
                    <div className="project">
                        <h2 className={"project__title text-center mt-5 pt-5"}>Mes réalisations</h2>
                        <div className={"row"}>

                            <div className={"col-md-12"}>
                                <SliderModule/>
                            </div>
                            <div id={'projectView'}></div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
