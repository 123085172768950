import React, {Component, Fragment} from 'react';
import  { Redirect } from 'react-router-dom';
import ReactDOM from 'react-dom';
import MainRouter from "../../routes/MainRouter";

export default class DetailProject extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: this.props.project.title,
            type: this.props.project.type,
            description: this.props.project.description,
            images: this.props.project.images,
            tech: this.props.project.tech,
            background: 'detail--dark',
            redirect: false,
        }
        this.close = this.close.bind(this);
    }

    componentWillUnmount() {
    }

    close() {
        window.history.pushState('', '', '/');
        ReactDOM.unmountComponentAtNode(document.getElementById('root'));
        ReactDOM.render(
            <Fragment>
                <div>
                    <MainRouter/>
                </div>
            </Fragment>,
            document.getElementById('root')
        );
        this.setState({redirect: true});
        document.getElementById('projectLink').click()
    }

    render(){

        return(

            <Fragment>
                <div className={'container-fluid'}>
                    {this.state.redirect ?<MainRouter><Redirect to="/"/></MainRouter> : ""}
                    <div className={this.state.background}>
                        <div className={"container"}>
                            <i onClick={this.close} className="detail__close fas fa-times"></i>
                            <h2 className={"project__title text-center mt-5 pt-5"}>{ this.state.name }</h2>
                            <div className={"row"}>

                                <div className={"col-md-6"}>
                                    <div className={"detail__info"}>
                                        <h4> Type: { this.state.type } </h4>
                                        <hr/>
                                        <h4>Description:</h4>
                                        <p>{ this.state.description }</p>
                                        <h4>Technologie:</h4>
                                        <p>{ this.state.tech }</p>
                                        <hr/>
                                    </div>
                                </div>
                                {this.state.images.length > 0 ? this.state.images.map((image, index) =>
                                    <div className={"col-md-6"} key={index}>
                                        <img className={"detail__img"} src={image.image} alt={image.name}></img>
                                    </div>) :null
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </Fragment>
        )
    }
}
