import React, {Component, Fragment} from 'react';
//import { AnimationOnScroll } from 'react-animation-on-scroll';
//import "animate.css/animate.min.css";
import ReactTooltip from 'react-tooltip';
import AOS from 'aos';
import 'aos/dist/aos.css';

import html from '../../assets/img/logo/front/htmlLogo.png';
import css from '../../assets/img/logo/front/cssLogo.png';
import js from '../../assets/img/logo/front/jsLogo.png';
import bootstrap from '../../assets/img/logo/front/bootstraplogo.png';
// import jquery from '../../assets/img/logo/front/jqueryLogo.png';
import angular from '../../assets/img/logo/front/angularLogo.png';
import react from '../../assets/img/logo/front/ReactLogo.png';
import redux from '../../assets/img/logo/front/reduxLogo.png';
import scss from '../../assets/img/logo/front/sass-scssLogo.png';
import affinity from '../../assets/img/logo/front/affinity-designerLogo.png';
import nextjs from '../../assets/img/logo/front/NextjsLogo.png';
import typescript from '../../assets/img/logo/front/typescriptLogo.png';
// import laravel from '../../assets/img/logo/back/laravelLogo.png';
import jest from '../../assets/img/logo/unit-test/jestLogo.png';


// import php from '../../assets/img/logo/back/phpLogo.png';
// import mysql from '../../assets/img/logo/back/mysqlLogo.png';
import expressjs from '../../assets/img/logo/back/expressjsLogo.png';
import mongodb from '../../assets/img/logo/back/mongodbLogo.png';
import java from '../../assets/img/logo/back/logo_java.jpg';
import postgres from '../../assets/img/logo/back/PostgreSQL.png';

export default class Skills extends Component {

    componentDidMount() {
        AOS.init({ duration : 1500});
    }

    render(){
        return(
            <Fragment>
                <div className={'container bef-skills'}>
                    <div className="skills">
                        <h2 className={'text-center skills__title'}>Mes compétences</h2>
                        <div className={"row"}>
                            <div className={"col-md-6"}>
                                <h3 className={'skills__subtitle pt-2 subtitle--front'}>Front <i className="skills__icon fas fa-desktop"></i></h3>
                                <div data-aos={"circle-outside"} className={"block__front"}>
                                    <div className={"d-flex justify-content-center"}>

                                        <ReactTooltip id={"html"}>Html</ReactTooltip>
                                        <div data-for='html' data-tip={"html"} className={"front__one"}>
                                            <img data-aos={"circle-inside"} className={"skills__logo--front "} src={html} alt={"Logo html"}/>
                                        </div>


                                    </div>
                                    <div className={"d-flex justify-content-around"}>
                                        <ReactTooltip id={"css"}>Css</ReactTooltip>
                                        <img data-for='css' data-tip={"css"} data-aos={"circle-inside"} className={"skills__logo--front front__two"} src={css} alt={"Logo css"}/>
                                        <ReactTooltip id={"javascript"}>Javascript</ReactTooltip>
                                        <img data-for='javascript' data-tip={"javascript"} data-aos={"circle-inside"} className={"skills__logo--front front__tree"} src={js} alt={"Logo javascript"}/>
                                    </div>

                                    <div className={"d-flex justify-content-around"}>
                                        <ReactTooltip id={"bootstrap"}>Bootstrap</ReactTooltip>
                                        <img data-for='bootstrap' data-tip={"bootstrap"} data-aos={"circle-inside"} className={"skills__logo--front front__four"} src={bootstrap} alt={"Logo bootstrap"}/>
                                        {/*<ReactTooltip id={"jquery"}>Jquery</ReactTooltip>*/}
                                        {/*<img data-for='jquery' data-tip={"jquery"} data-aos={"circle-inside"} className={"skills__logo--front front__five"} src={jquery} alt={"Logo jquery"}/>*/}
                                        <ReactTooltip id={"typescript"}>Typescript</ReactTooltip>
                                        <img data-for='typescript' data-tip={"typescript"} data-aos={"circle-inside"} className={"skills__logo--front front__five"} src={typescript} alt={"Logo typescript"}/>
                                    </div>
                                    <div className={"d-flex justify-content-around"}>
                                        <ReactTooltip id={"angular"}>Angular</ReactTooltip>
                                        <img data-for='angular' data-tip={"angular"} data-aos={"circle-inside"} className={"skills__logo--front front__six"} src={angular} alt={"Logo angular"}/>
                                        <ReactTooltip id={"react"}>React</ReactTooltip>
                                        <img data-for='react' data-tip={"react"} data-aos={"circle-inside"} className={"skills__logo--front front__seven"} src={react} alt={"Logo react"}/>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-md-6"}>
                                <h3 className={'skills__subtitle subtitle--back pt-2'}>Back <i className="skills__icon fas fa-server"></i></h3>
                                <div className={"block__back"}>
                                    <div className={'d-flex justify-content-around'}>
                                        {/*<img className={"skills__logo--back back__one"} src={php} alt={"Logo php"}/>*/}
                                        {/*<img className={"skills__logo--back back__two"} src={mysql} alt={"Logo mysql"}*/}
                                        <img className={"skills__logo--back back__one"} src={java} alt={"Logo java"}/>
                                        <img className={"skills__logo--back back__two"} src={postgres} alt={"Logo postgres Sql"}/>
                                    </div>
                                    <div className={'back__js d-flex justify-content-between'}>
                                        <img className={"skills__logo--back back__tree"} src={expressjs} alt={"Logo express js"}/>
                                        <img className={"skills__logo--back back__four"} src={mongodb} alt={"Logo mongodb"}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'row mt-5'}>


                            <div className={'col-5 mt-5 ps-4 d-flex justify-content-end'}>
                                <div>
                                    <h3 className={'skills__title text-center'}>Autre</h3>
                                    <hr/>
                                    <div data-aos={"fade-right"} className={'d-flex little__box--skill mt-3'}>
                                        <img className={"skills__logo--front"} src={scss} alt={"Logo scss"}/>
                                        <p className={"ps-2 text-center"}>Scss</p>
                                    </div>
                                    <div data-aos={"fade-right"} className={'d-flex mt-3 little__box--skill'}>
                                        <img className={"skills__logo--front"} src={redux} alt={"Logo redux"}/>
                                        <p className={"ps-2"}>Redux</p>
                                    </div>
                                    <div data-aos={"fade-right"} className={'d-flex mt-3 little__box--skill'}>
                                        <img className={"skills__logo--front"} src={affinity} alt={"Logo affinity"}/>
                                        <p className={"ps-2"}>Affinity Designer</p>
                                    </div>
                                </div>


                            </div>
                            {/*<hr className={'mt-5'}/>*/}

                            <div className={"col-2 mt-5 d-flex justify-content-center"}>
                                <div className={"bar--vertical"}/>
                            </div>

                            <div className={'col-5 mt-5 d-flex justify-content-start'}>
                                    <div>
                                        <h3 className={"skills__title text-center"}>En cour</h3>
                                        <hr/>
                                        <div data-aos={"fade-left"} className={'d-flex little__box--skill mt-3'}>
                                            <img className={"skills__logo--front"} src={nextjs} alt={"Logo next js"}/>
                                        </div>
                                        {/*<div data-aos={"fade-left"} className={'d-flex mt-3 little__box--skill'}>
                                            <img className={"skills__logo--front"} src={typescript} alt={"Logo typescript"}/>
                                            <p className={"ps-2"}>Typescript</p>
                                        </div>*/}
                                        <div data-aos={"fade-left"} className={'d-flex mt-3 little__box--skill'}>
                                            <img className={"skills__logo--front"} src={jest} alt={"Logo jest"}/>
                                            <p className={"ps-2"}>Jest</p>
                                        </div>
                                    </div>


                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
