import React, {Component, Fragment} from 'react';
import './Home.scss';
import Presentation from "../components/presentation/presentation";
import CV from "../components/cv/cv"
import Skills from "../components/skills/skills";
import Project from "../components/project/project";
//import Contact from "../components/contact/contact";

export default class Home extends Component {

    render(){
        return(
            <Fragment>
                <div className={'container'}>
                    <div className="accueil">
                        <div className={'row justify-content-center'}>
                            <Presentation/>
                        </div>
                    </div>
                </div>
                <div className={"before__container"}></div>
                    <div className={'container__cv'}>
                        <CV/>
                    </div>
                <div className={"after__container"}></div>
                <Skills/>
                <div className={"container__project"}>
                    <Project/>
                </div>
                {/*<div className={"container__contact"}>
                    <Contact/>
        </div>*/}


            </Fragment>
        )
    }
}
