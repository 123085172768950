import React, {Component, Fragment} from 'react';
import {Switch ,Route, BrowserRouter} from "react-router-dom";
import Home from "../views/Home";
import Navbar from "../components/navbar/navbar";

export default class MainRouter extends Component {

    render() {

        return (
            <Fragment>
                <main className="mainRouter">
                    <div>
                        <Navbar/>
                        <BrowserRouter>
                            <Switch>
                                <Route path={"/"} component={Home}/>
                            </Switch>
                        </BrowserRouter>
                    </div>
                </main>
            </Fragment>
        )
    }
}
