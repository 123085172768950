import React, {useState, useEffect, Fragment} from 'react';
import ReactDOM from 'react-dom';
import Slider from "react-slick";
import ReactTooltip from 'react-tooltip';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import spent from '../../assets/img/project/depenseFC.png';
import spent2 from '../../assets/img/project/depenseFCIM.png';
import spent3 from '../../assets/img/project/depenseFH.png';
import freeAds from '../../assets/img/project/free.png';
import freeAds2 from '../../assets/img/project/freeAdsSelect.png';
import freeAds3 from '../../assets/img/project/freeAdsModify2.png';
import lilie from '../../assets/img/project/lilieBottom.png';
import quizz from '../../assets/img/project/QuizzChoose.png';
import quizz2 from '../../assets/img/project/QuizzHistory.png';
import quizz3 from '../../assets/img/project/QuizzHome.png';
import tweeter from '../../assets/img/project/Tweeter.png';
import tweeter2 from '../../assets/img/project/TweeterProfil.png';
import tweeter3 from '../../assets/img/project/TweeterFollow.png';
import tweeter4 from '../../assets/img/project/TweeterModify.png';
import DetailProject from "../project/detailProject";

export default function SliderModule() {
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const [slider1, setSlider1] = useState(null);
    const [slider2, setSlider2] = useState(null);

    useEffect(() => {
        setNav1(slider1);
        setNav2(slider2);
    }, [slider1, slider2]);

    const settingsMain = {
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: (
            <div>
                <i className="next-slick-arrow fas fa-chevron-circle-right carousel--arrow" alt={"Fleche navigation de droite"}></i>
            </div>
        ),
        prevArrow: (
            <div>
                <i className="prev-slick-arrow fas fa-chevron-circle-left carousel--arrow" alt={"Fleche navigation de gauche"}></i>
            </div>
        ),
        fade: true,
        asNavFor: '.slider-nav'
    };

    const settingsThumbs = {
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '.slider-for',
        dots: true,
        centerMode: true,
        swipeToSlide: true,
        focusOnSelect: true,
        centerPadding: '10px'
    };

    const slidesData = [
        {
            project: {
                id: 1,
                title: 'dépense',
                type: 'Web',
                description: 'Site de gestion de budget.',
                tech: 'Angular, Material UI, MongoDb',
                color: null,
                images: [{name: '', image: spent2}, {name: '', image: spent3}, {name: '', image: spent}],

            },
            label: 'Site de gestion de budget. Angular, Material UI, MongoDb',
            image: spent,
            alt: "Site dépense",
        }, {
            project: {
                id: 2,
                title: 'Free Ads',
                type: 'Web',
                description: 'Site de petites annonces.',
                tech: 'Laravel, Mysql',
                color: 'light',
                images: [{name: 'free Ads', image: freeAds}, {name: 'free Ads Select', image: freeAds2}, {name: 'free Ads Modify', image: freeAds3}],

            },
            label: 'Site de petites annonces. Laravel, Mysql',
            image: freeAds,
            alt: "Site freeAds",
        }, {
            project: {
                id: 3,
                title: 'Soprologie',
                type: 'Web',
                description: 'Site vitrine',
                tech: 'html css',
                color: 'light',
                images: [{name: '', image: lilie}],

            },
            label: 'Site vitrine, html css',
            image: lilie,
            alt: "Site soprologie",
        }, {
            project: {
                id: 4,
                title: 'Sushi Quizz',
                type: 'Web',
                description: 'Site de Creation et réponse au questionnaire. Projet de groupe',
                tech: 'Symphony, mysql',
                color: 'dark',
                images: [{name: 'Sushi quizz', image: quizz},{name: 'Sushi quizz', image: quizz2},{name: 'Sushi quizz', image: quizz3}],

            },
            label: 'Site de Creation et réponse au questionnaire. Projet de groupe',
            image: quizz,
            alt: "Site de quizz",
        }, {
            project: {
                id: 5,
                title: 'tweeter',
                type: 'Web',
                description: 'Site comme twitter',
                tech: 'Html, CSS, JS, PHP, JQuery, mysql',
                color: '#444',
                images: [{name: 'tweeter', image: tweeter2}, {name: 'tweeter', image: tweeter3}, {name: 'tweeter', image: tweeter4}],

            },
            label: 'Site comme twitter. Projet de groupe',
            image: tweeter,
            alt: "Site tweeter",
        },
    ];

    function viewProject(project) {
        window.history.pushState('', '', '/project/'+ project.title.replace(/\s/g, ''));
        ReactDOM.render(
            <Fragment>
                <div id={'DetailProject'}>
                    <DetailProject  project={project}/>
                </div>
            </Fragment>,
            document.getElementById('root')
        );
    }

    return (

        <div className="slider__content">

            <div className="slider-wrapper">
                <ReactTooltip id={"project"}>Ouvrir</ReactTooltip>

                <Slider
                    {...settingsMain}
                    asNavFor={nav2}
                    ref={slider => (setSlider1(slider))}
                >
                    {slidesData.map((slide) =>

                        <div data-for='project' data-tip={"project"} onClick={() => viewProject(slide.project)} className="slick-slide" key={slide.project.id}>
                            <h3 className="slick-slide-title">{slide.project.title}</h3>
                            <img className="slick-slide-image" src={slide.image} alt={slide.alt}/>
                            <label className="slick-slide-label">{slide.label}</label>
                        </div>
                    )}
                </Slider>
                <div className="thumbnail-slider-wrap pb-5">
                    <Slider
                        {...settingsThumbs}
                        asNavFor={nav1}
                        ref={slider => (setSlider2(slider))}>

                        {slidesData.map((slide) =>

                            <div className="slick-slide" key={slide.project.id}>
                                <img className="slick-slide-image" src={slide.image} alt={slide.alt}/>
                            </div>
                        )}
                    </Slider>
                </div>
            </div>
        </div>
    );
}
