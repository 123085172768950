import React, {Component, Fragment} from 'react';
import Profile from "../../assets/img/photoR.png";

export default class Presentation extends Component {

    render(){
        return(
            <Fragment>
                <div className={'container bef-presentation'}>
                    <div className="presentation">
                        <div className={"row"}>
                            <div className={"col-md-7"}>
                                <h2 className={'presentation__name text-sm-center text-md-left'}>Jérôme Botiveau</h2>
                                <p className={'presentation__paragraph text-sm-center text-md-left'}>
                                    Passionné par le développement web, j'aime expérimenter, découvrir et apprendre des nouvelles technologies.
                                    De nature curieuse j'ai donc choisi de faire une
                                    reconversion professionnelle dans ce domaine.
                                </p>
                            </div>
                            <div className={'col-md-5 container__photo'}>
                                <img className={'presentation__photo'} src={Profile} alt={"Profil"}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
