import React from 'react'
import {BrowserRouter as Router } from "react-router-dom";
import './App.scss';
import MainRouter from "./routes/MainRouter";

function App( {state} ) {
  return (
      <Router>
        <MainRouter state={state}/>
      </Router>
  )
}

export default App;

